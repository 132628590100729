export const capitalize = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const formatName = (firstName: string, familyName: string) => {
  return `${firstName
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ")} ${familyName.toUpperCase()}`;
};
