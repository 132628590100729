import { Modal } from "@mantine/core";
import router from "next/router";
import { ModalClose } from "@/public/icons/ModalClose";
import { routes } from "@/routes/routes";
import { injectPurifiedHTML } from "@/utils/injectPurifiedHTML";
import { removeEmptyRel } from "@/utils/removeEmptyRel";
import styles from "./LegalModal.module.scss";

type LegalModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpened: boolean) => void;
  description: string;
};

export const LegalModal = ({
  isOpen,
  setIsOpen,
  description,
}: LegalModalProps) => {
  function closeModale() {
    setIsOpen(false);
    void router.push(routes.home);
  }

  return (
    <Modal
      opened={isOpen}
      centered
      withCloseButton={false}
      onClose={() => setIsOpen(false)}
      classNames={{
        modal: styles.modalRoot,
        body: styles.modalBody,
        overlay: styles.modalOverlay,
      }}
    >
      <button className={styles.modalClose} onClick={closeModale}>
        <ModalClose color="#062E5E" />
      </button>
      <p
        className={styles.modalText}
        dangerouslySetInnerHTML={injectPurifiedHTML(
          removeEmptyRel(description)
        )}
      ></p>
    </Modal>
  );
};
