export const LinkedInIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35}>
    <g data-name="invisible box">
      <path
        data-name="Rectangle 1021"
        style={{
          fill: "none",
        }}
        d="M0 0h35v35H0z"
      />
      <path
        data-name="Rectangle 1022"
        style={{
          fill: "none",
        }}
        d="M0 0h35v35H0z"
      />
    </g>
    <g data-name="icons Q2">
      <path
        data-name="Trac\xE9 8232"
        d="M28.945 4.1H6.023A1.955 1.955 0 0 0 4 6.055v22.99A1.955 1.955 0 0 0 6.023 31h22.922a1.955 1.955 0 0 0 1.955-1.955V6.055A1.955 1.955 0 0 0 28.945 4.1zM12.023 27.023H7.978v-12.81h4.045zM10 12.46a2.36 2.36 0 0 1-2.292-2.36 2.292 2.292 0 1 1 4.584 0A2.36 2.36 0 0 1 10 12.46zm16.922 14.563h-3.977v-6.27c0-1.483 0-3.371-2.09-3.371s-2.36 1.618-2.36 3.3v6.338h-3.978V14.213h3.775v1.753h.067a4.18 4.18 0 0 1 3.775-2.09c4.045 0 4.787 2.629 4.787 6.135z"
        style={{
          fill: "#0e76a8",
        }}
      />
    </g>
  </svg>
);
