type LoginIconProps = {
  color: string;
};

export const LoginIcon = ({ color }: LoginIconProps) => (
  <svg width="60" height="60" viewBox="0 0 50 50">
    <path
      data-name="Rectangle 1165"
      style={{ fill: "none" }}
      d="M0 0h50v50H0z"
    />
    <g data-name="Groupe 9431">
      <g
        data-name="Ellipse 868"
        transform="translate(18.655 14)"
        style={{ strokeWidth: "1.8px", fill: "none" }}
        stroke={color}
      >
        <circle cx="6.5" cy="6.5" r="6.5" style={{ stroke: "none" }} />
        <circle cx="6.5" cy="6.5" r="5.6" style={{ fill: "none" }} />
      </g>
      <path
        data-name="Tracé 11229"
        d="M-8361.631-10879.987c.219-3.556 2.171-9.027 9.641-9.3a9.959 9.959 0 0 1 10.67 9.3"
        transform="translate(8376.631 10916)"
        style={{
          strokeLinecap: "round",
          strokeWidth: "2px",
          fill: "none",
        }}
        stroke={color}
      />
    </g>
  </svg>
);
