import Head from "next/head";
import { Footer, FooterProps } from "@/components/layout/footer/Footer";
import { HeaderLegacy } from "@/components/layout/headerLegacy/HeaderLegacy";
import { SEO } from "@/interfaces/seo";
import { Breadcrumbs } from "./breadcrumbs/Breadcrumbs";
import { Section } from "../common/section/Section";
import { Menu } from "./headerLegacy/menuDesktop/MenuDesktop";
import styles from "./Layout.module.scss";

export type Layout = {
  id: string;
  createdAt: Date;
  publishedAt: Date;
  updatedAt: Date;
  footer: FooterProps;
  menu: Menu;
};

type LayoutProps = {
  children: React.ReactNode;
  layout: Layout;
  defaultCanonicalRoute: string;
  title?: string;
  description?: string;
  pageMeta?: SEO;
  breadcrumbs?: { title: string; url: string }[];
};

const BASE_URL = `https://${process.env.NEXT_PUBLIC_DOMAIN ?? ""}`;

const isCanonicalOverride = (
  pageMeta?: SEO
): pageMeta is SEO & { canonicalURL: string } =>
  pageMeta?.canonicalURL !== null &&
  pageMeta?.canonicalURL !== "" &&
  pageMeta !== undefined;

export const Layout: React.FC<LayoutProps> = ({
  children,
  layout,
  defaultCanonicalRoute,
  title,
  description,
  pageMeta,
  breadcrumbs,
}) => (
  <>
    <Head>
      {title !== undefined ? (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} key="title" />
        </>
      ) : null}
      {description !== undefined ? (
        <meta content={description} name="description" key="description" />
      ) : null}
      {pageMeta ? (
        <meta content={pageMeta.metaRobots} name="robots" key="robots" />
      ) : null}

      <meta name="author" content="Rentacar" key="author" />

      <link
        href={
          pageMeta && isCanonicalOverride(pageMeta)
            ? pageMeta.canonicalURL
            : `${BASE_URL}${defaultCanonicalRoute}`
        }
        rel="canonical"
      />
    </Head>
    <div className={styles.main}>
      <div>
        <HeaderLegacy menu={layout.menu} />
      </div>

      <div className={styles.body}>
        <Section>
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        </Section>

        <div>{children}</div>
      </div>

      <Footer {...layout.footer} />
    </div>
  </>
);

export default Layout;
