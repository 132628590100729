export const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    viewBox="0 0 35 35"
  >
    <g id="invisible_box" data-name="invisible box">
      <rect
        id="Rectangle_1026"
        data-name="Rectangle 1026"
        width="35"
        height="35"
        fill="none"
      />
    </g>
    <g id="icons_Q2" data-name="icons Q2" transform="translate(1.458 4.421)">
      <path
        id="Tracé_8233"
        data-name="Tracé 8233"
        d="M33.75,8.069a13.344,13.344,0,0,1-3.792,1.094,6.854,6.854,0,0,0,2.917-3.646,16.917,16.917,0,0,1-4.156,1.6,6.635,6.635,0,0,0-11.448,4.448A5.542,5.542,0,0,0,17.49,13.1,18.813,18.813,0,0,1,3.927,6.246a6.2,6.2,0,0,0-.948,3.281A6.49,6.49,0,0,0,5.969,15a5.906,5.906,0,0,1-2.99-.8h0a6.49,6.49,0,0,0,5.25,6.49,6.271,6.271,0,0,1-1.75.292l-1.24-.146a6.708,6.708,0,0,0,6.2,4.594,13.271,13.271,0,0,1-8.167,2.771h-1.6a18.3,18.3,0,0,0,10.062,2.99c12.1,0,18.74-10.062,18.74-18.74V11.5A13.344,13.344,0,0,0,33.75,8.069Z"
        transform="translate(-1.667 -5.053)"
        fill="#00acee"
      />
    </g>
  </svg>
);
