import Link from "next/link";

type WithLinkProps = {
  children: React.ReactNode;
  key: string;
  url: string | null;
  external: boolean | null;
};

const redirectToExternalUrl = (url: string) => (window.location.href = url);

export const withLink = ({ children, key, url, external }: WithLinkProps) =>
  url !== null ? (
    external ? (
      <button onClick={() => redirectToExternalUrl(url)}>{children}</button>
    ) : (
      <Link key={key} href={url}>
        <a>{children}</a>
      </Link>
    )
  ) : (
    <>{children}</>
  );
