import Link from "next/link";
import { useIntl } from "react-intl";
import { useState } from "react";
import { LegalModal } from "@/components/common/legalModal/LegalModal";
import { Section } from "@/components/common/section/Section";
import styles from "./LegalLinksBlock.module.scss";

export type LegalLinksBlockProps = {
  legalLinks: LegalLink[];
};

export type LegalLink = {
  type: "link" | "modal";
  name: string;
  url: string;
  description: string;
};

declare global {
  interface Window {
    openAxeptioCookies: () => void;
  }
}

export const LegalLinksBlock = ({ legalLinks }: LegalLinksBlockProps) => {
  const intl = useIntl();
  const [modalOpened, setModalOpened] = useState(false);
  const [modalDescription, setModalDescription] = useState<string>("");

  const openModal = (description: string) => {
    setModalDescription(description);
    setModalOpened(true);
  };

  return (
    <Section containerStyle={styles.legalLinksSectionColor}>
      <div className={styles.legalLinksBlock}>
        {legalLinks.map((legalLink) => (
          <Link href={legalLink.url} key={legalLink.name}>
            <a className={styles.legalLink} rel="nofollow">
              {legalLink.name}
            </a>
          </Link>
        ))}
        {/* Solution remporaire en attendant que Strapi corrige ses problèmes */}
        <button
          className={styles.legalLink}
          onClick={() => openModal(intl.formatMessage({ id: "blocktelText" }))}
        >
          {intl.formatMessage({ id: "blocktelSetup" })}
        </button>
        <button
          className={styles.legalLink}
          onClick={() => window.openAxeptioCookies()}
        >
          {intl.formatMessage({ id: "cookiesSetup" })}
        </button>
      </div>
      <LegalModal
        isOpen={modalOpened}
        setIsOpen={setModalOpened}
        description={modalDescription}
      />
    </Section>
  );
};
