import { FooterProps } from "@/components/layout/footer/Footer";

import {
  AgenciesListFooter,
  AgenciesListFooterProps,
} from "@/components/layout/footer/agenciesAndSocialMediaBlock/agenciesListFooter/AgenciesListFooter";
import { Section } from "@/components/common/section/Section";
import styles from "./AgenciesAndSocialMediaBlock.module.scss";
import { SocialMedia } from "./socialMedia/SocialMedia";

export type AgenciesBlock = {
  agenciesLists: AgenciesListFooterProps[];
};

type AgenciesAndSocialMediaBlockProps = Pick<FooterProps, "agenciesBlock">;

export const AgenciesAndSocialMediaBlock = ({
  agenciesBlock,
}: AgenciesAndSocialMediaBlockProps) => {
  const getUID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  return (
    <Section>
      <div className={styles.agenciesAndSocialMediaBlock}>
        <div className={styles.agenciesAndSocialMediaContainerLayout}>
          <div className={styles.agenciesAndSocialMediaCardsLayout}>
            {agenciesBlock.agenciesLists.map((agenciesListFooter) => (
              <AgenciesListFooter
                key={agenciesListFooter.title + getUID()}
                {...agenciesListFooter}
              />
            ))}
            <div className={styles.socialMediaBlock}>
              <SocialMedia />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
