export const BurgerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="50"
    viewBox="0 0 51 50"
  >
    <g id="Menu_" data-name="Menu " transform="translate(51 50) rotate(180)">
      <rect
        id="Rectangle_842"
        data-name="Rectangle 842"
        width="50"
        height="50"
        fill="none"
      />
      <g
        id="Groupe_8224"
        data-name="Groupe 8224"
        transform="translate(49 46) rotate(180)"
      >
        <rect
          id="Rectangle_838"
          data-name="Rectangle 838"
          width="51"
          height="50"
          transform="translate(-2 -4)"
          fill="none"
        />
        <g id="menu" transform="translate(13 14)">
          <rect
            id="middle"
            width="21"
            height="1.853"
            rx="0.927"
            transform="translate(0 6.561)"
            fill="#fff"
          />
          <g id="Groupe_8213" data-name="Groupe 8213">
            <rect id="top" width="10.667" height="2" rx="1" fill="#fff" />
            <rect
              id="bot"
              width="17.333"
              height="2"
              rx="1"
              transform="translate(-0.311 13.353)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
