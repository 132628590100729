export const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35}>
    <g data-name="invisible box">
      <path
        data-name="Rectangle 1019"
        style={{
          fill: "none",
        }}
        d="M0 0h35v35H0z"
      />
      <path
        data-name="Rectangle 1020"
        style={{
          fill: "none",
        }}
        d="M0 0h35v35H0z"
      />
    </g>
    <g data-name="icons Q2">
      <path
        data-name="Trac\xE9 8231"
        d="M31 5.448v24.136a.2.2 0 0 1-.067.135 1.282 1.282 0 0 1-1.215 1.214H22.63V20.55h3.308c.2 0 .2 0 .27-.2l.2-1.685c.067-.674.135-1.348.27-2.023s0-.2-.135-.2H22.63v-2.634c0-.2.067-.472.067-.674a1.214 1.214 0 0 1 1.08-1.079l.877-.135h2.026c.135 0 .135 0 .135-.135V8.347c0-.067 0-.135-.135-.135h-3.1a4.865 4.865 0 0 0-2.227.472 4.048 4.048 0 0 0-2.362 2.09 4.917 4.917 0 0 0-.54 2.427c-.068 1.011 0 2.09 0 3.169H15c-.135 0-.135.067-.135.135v3.843c0 .135 0 .135.135.135h3.443v10.382a.2.2 0 0 0-.068.135H5.283a1.282 1.282 0 0 1-1.215-1.214C4 29.786 4 29.719 4 29.652V5.448c0-.067 0-.135.068-.135A1.282 1.282 0 0 1 5.283 4.1h24.434a1.282 1.282 0 0 1 1.215 1.214z"
        style={{
          fill: "#3b5998",
        }}
      />
    </g>
  </svg>
);
