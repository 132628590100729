import { Dispatch, SetStateAction } from "react";

import {
  SectionWithLinks,
  SectionWithLinksProps,
} from "@/components/layout/headerLegacy/menuMobile/sectionWithLinks/SectionWithLinks";

import { ArrowIcon } from "@/public/icons/ArrowIcon";

import styles from "./SectionWithSubSections.module.scss";

type SubSectionWithLinks = Omit<
  SectionWithLinksProps,
  "sectionOpened" | "setSectionOpened" | "setIsOpen"
>;

type SectionWithSubSectionsProps = {
  title: string;
  subSectionsWithLinks: SubSectionWithLinks[];
  sectionOpened: string;
  setSectionOpened: Dispatch<SetStateAction<string>>;
  subSectionOpened: string;
  setSubSectionOpened: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const SectionWithSubSections = ({
  title,
  subSectionsWithLinks,
  sectionOpened,
  setSectionOpened,
  subSectionOpened,
  setSubSectionOpened,
  setIsOpen,
}: SectionWithSubSectionsProps) => (
  <div>
    {!sectionOpened && (
      <div
        className={styles.sectionWithSubSectionsTitle}
        onClick={() => {
          setSectionOpened(title);
        }}
      >
        <p>{title}</p>
        <div className={styles.arrowContainer}>
          <ArrowIcon color="#445871" />
        </div>
      </div>
    )}
    {sectionOpened === title && (
      <div>
        {!subSectionOpened && (
          <div className={styles.sectionWithSubSectionsTitleOpened}>
            <p>{title}</p>
            <div className={styles.downArrowContainer}>
              <ArrowIcon color="#062e5e" />
            </div>
          </div>
        )}
        {subSectionsWithLinks.map(
          ({ title: subSectionWithLinksTitle, links }) => (
            <SectionWithLinks
              title={subSectionWithLinksTitle}
              links={links}
              sectionOpened={subSectionOpened}
              setSectionOpened={setSubSectionOpened}
              key={subSectionWithLinksTitle}
              setIsOpen={setIsOpen}
            />
          )
        )}
      </div>
    )}
  </div>
);
