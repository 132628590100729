type ArrowIconProps = {
  color: string;
  size?: number;
  className?: string;
};

export const ArrowIcon = ({ color, size = 18, className }: ArrowIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    className={className}
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.5 3.55l4.95 4.95-4.95 4.95"
    />
  </svg>
);
