import Link from "next/link";
import styles from "./MenuDesktop.module.scss";

export type Menu = {
  menuSection: {
    type: string;
    title: string;
    column?: {
      title: string;
      links: {
        title: string;
        url: string;
      }[];
    }[];
    row?: {
      rows?: {
        title: string;
        links: {
          title: string;
          url: string;
        }[];
      }[];
    };
    single?: {
      title: string;
      url: string;
    }[];
  }[];
};

export type MenuDesktopProps = {
  menu: Menu;
};

export enum SectionType {
  COLUMN = "COLUMN",
  ROW = "ROW",
  SINGLE = "SINGLE",
}

export const MenuDesktop = ({ menu }: MenuDesktopProps) => {
  return (
    <ul className={styles.menu}>
      {menu.menuSection.map((section, i) => (
        <li className={styles.menuSection} key={i}>
          <div className={styles.menuSectionTitle}>{section.title}</div>
          <div className={styles.menuSectionLinks}>
            <div className={styles.arrow} />
            {section.type === SectionType.COLUMN && (
              <div className={styles.columns}>
                {section.column?.map((col, j) => (
                  <ul className={styles.column} key={j}>
                    <li>
                      <div className={styles.arrow} />
                      <div className={styles.title}>{col.title}</div>
                    </li>
                    {col.links.map((link, k) => (
                      <li className={styles.link} key={k}>
                        <Link href={link.url}>{link.title}</Link>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            )}
            {section.type === SectionType.ROW && (
              <div className={styles.rows}>
                {section.row?.rows?.map((row, l) => (
                  <ul className={styles.row} key={l}>
                    <li>
                      <div className={styles.arrow} />
                      <p className={styles.title}>{row.title}</p>
                    </li>
                    {row.links.map((link, m) => (
                      <li className={styles.link} key={m}>
                        <Link href={link.url}>{link.title}</Link>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            )}
            {section.type === SectionType.SINGLE && (
              <ul className={styles.single}>
                {section.single?.map((link, m) => (
                  <li className={styles.link} key={m}>
                    <Link href={link.url}>{link.title}</Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};
