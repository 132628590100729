import {
  ReassuranceCardContent,
  ReassuranceCardProps,
} from "@/components/layout/footer/reassuranceBlock/reassuranceCard/reassuranceCardContent/ReassuranceCardContent";
import { withLink } from "@/components/layout/footer/reassuranceBlock/reassuranceCard/withLink";

export const ReassuranceCard = (cardContent: ReassuranceCardProps) =>
  withLink({
    children: <ReassuranceCardContent {...cardContent} />,
    key: "link",
    url: cardContent.url,
    external: true,
  });
