import classNames from "classnames";
import styles from "./Section.module.scss";

type SectionProps = {
  containerStyle?: string;
  children: React.ReactNode;
  sectionStyle?: string;
};

export const Section = ({
  children,
  containerStyle,
  sectionStyle,
}: SectionProps) => {
  return (
    <div className={classNames(styles.sectionContainer, containerStyle)}>
      <div className={classNames(styles.section, sectionStyle)}>{children}</div>
    </div>
  );
};
