export const RentACarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={160.409} height={33}>
    <g data-name="Groupe 8864">
      <path
        data-name="Trac\xE9 1049"
        d="M57.528 137.782H60.7a2.612 2.612 0 0 0 2.86-2.733 2.578 2.578 0 0 0-2.86-2.705h-3.17zM52.91 130.5a2.174 2.174 0 0 1 2.368-2.4h5.41c4.736 0 7.651 2.122 7.651 6.95 0 3.379-2.55 5.319-5.684 5.811l5.228 5.593a2.012 2.012 0 0 1 .617 1.346 2.355 2.355 0 0 1-2.3 2.368 2.383 2.383 0 0 1-1.785-.8l-6.823-8.268h-.064v6.667a2.306 2.306 0 1 1-4.609 0V130.5z"
        transform="translate(-52.91 -123.701)"
        style={{
          fill: "#dfe1e4",
        }}
      />
    </g>
    <g data-name="Groupe 8865">
      <path
        data-name="Trac\xE9 1050"
        d="M73.029 130.778c0-1.539.765-2.678 2.4-2.678h7.624a2.051 2.051 0 0 1 2.186 2.122 2.081 2.081 0 0 1-2.186 2.122h-5.41v4.49h5.046a2.082 2.082 0 0 1 2.241 2.122 2.112 2.112 0 0 1-2.241 2.122h-5.051v4.673h5.684a2.051 2.051 0 0 1 2.186 2.122A2.081 2.081 0 0 1 83.322 150h-7.934a2.241 2.241 0 0 1-2.368-2.3v-16.922z"
        transform="translate(-54.703 -123.701)"
        style={{
          fill: "#dfe1e4",
        }}
      />
    </g>
    <g data-name="Groupe 8866">
      <path
        data-name="Trac\xE9 1051"
        d="M89.81 130.3a2.2 2.2 0 0 1 2.3-2.4 2.909 2.909 0 0 1 1.94 1.011l9.746 13.007h.064V130.3a2.306 2.306 0 1 1 4.609 0v17.461a2.2 2.2 0 0 1-2.3 2.4 2.989 2.989 0 0 1-1.94-1.011l-9.746-12.852h-.064v11.468a2.306 2.306 0 1 1-4.609 0z"
        transform="translate(-56.2 -123.683)"
        style={{
          fill: "#dfe1e4",
        }}
      />
    </g>
    <g data-name="Groupe 8867">
      <path
        data-name="Trac\xE9 1052"
        d="M118 132.345h-3.352a1.981 1.981 0 0 1-2.086-2.122 2.057 2.057 0 0 1 2.086-2.122h11.313a2.065 2.065 0 0 1 2.095 2.122 1.989 1.989 0 0 1-2.095 2.122h-3.352v15.43a2.306 2.306 0 1 1-4.609 0z"
        transform="translate(-58.228 -123.701)"
        style={{
          fill: "#dfe1e4",
        }}
      />
    </g>
    <g data-name="Groupe 8863">
      <path
        data-name="Trac\xE9 1053"
        d="M132.048 155.44H54.8a1.849 1.849 0 1 0 0 3.7h80.856a62.717 62.717 0 0 1-3.607-3.7"
        transform="translate(-52.914 -126.138)"
        style={{
          fill: "#dfe1e4",
        }}
      />
    </g>
    <g data-name="Groupe 8862">
      <path
        data-name="Trac\xE9 1054"
        d="M220.332 155.44h-64.415q-1.64 1.844-3.607 3.7h68.022a1.849 1.849 0 0 0 0-3.7"
        transform="translate(-61.772 -126.138)"
        style={{
          fill: "#dfe1e4",
        }}
      />
    </g>
    <g data-name="Groupe 8860">
      <path
        data-name="Trac\xE9 1056"
        d="M176.917 127.7c2.241 0 6.613.738 6.613 3.443a1.946 1.946 0 0 1-1.9 2.059c-1.257 0-2.122-1.075-4.7-1.075-3.807 0-5.784 3.224-5.784 6.977 0 3.662 1.995 6.795 5.784 6.795 2.587 0 3.625-1.293 4.891-1.293a2.165 2.165 0 0 1 2.031 2.086c0 2.951-4.645 3.625-6.913 3.625-6.239 0-10.575-4.946-10.575-11.313-.018-6.385 4.281-11.3 10.557-11.3"
        transform="translate(-63.024 -123.665)"
        style={{
          fill: "#dfe1e4",
        }}
      />
    </g>
    <g data-name="Groupe 8861">
      <path
        data-name="Trac\xE9 1057"
        d="M194.541 141.408h5.839l-2.86-8.917h-.064zm-.341-11.158a3.614 3.614 0 0 1 3.288-2.55 3.471 3.471 0 0 1 3.288 2.55l6.03 16.723a3.636 3.636 0 0 1 .246 1.139 2.048 2.048 0 0 1-2.122 2.031 2.14 2.14 0 0 1-2.241-1.6l-.92-2.887h-8.549l-.92 2.86a2.137 2.137 0 0 1-2.213 1.63 2.206 2.206 0 0 1-2.277-2.15 2.558 2.558 0 0 1 .219-1.011z"
        transform="translate(-64.937 -123.665)"
        style={{
          fill: "#dfe1e4",
        }}
      />
    </g>
    <g data-name="Groupe 8858">
      <path
        data-name="Trac\xE9 1058"
        d="M216.479 137.782h3.17a2.612 2.612 0 0 0 2.86-2.733 2.578 2.578 0 0 0-2.86-2.705h-3.17zm-4.609-7.282a2.174 2.174 0 0 1 2.368-2.4h5.41c4.736 0 7.651 2.122 7.651 6.95 0 3.379-2.55 5.319-5.684 5.811l5.228 5.593a2.012 2.012 0 0 1 .619 1.348 2.355 2.355 0 0 1-2.3 2.368 2.383 2.383 0 0 1-1.785-.8l-6.822-8.271h-.064v6.667a2.306 2.306 0 1 1-4.609 0V130.5z"
        transform="translate(-67.082 -123.701)"
        style={{
          fill: "#dfe1e4",
        }}
      />
    </g>
    <g data-name="Groupe 8859">
      <path
        data-name="Trac\xE9 1059"
        d="M146.81 123.27c6.9 0 12.469 5.31 12.469 11.859-.027 10.038-10.766 18.891-12.469 20.485-1.7-1.594-12.442-10.438-12.469-20.485 0-6.549 5.583-11.859 12.469-11.859h.009"
        transform="translate(-60.17 -123.27)"
        style={{
          fill: "#dfe1e4",
        }}
      />
    </g>
    <g data-name="Groupe 8868">
      <path
        data-name="Trac\xE9 1060"
        d="M144.005 137.488c0 1.913 1.011 3.507 2.842 3.507s2.842-1.594 2.842-3.507-1.011-3.507-2.842-3.507-2.842 1.594-2.842 3.507m9.819 5.064a2.057 2.057 0 0 1-2.068 2.15 2.143 2.143 0 0 1-1.94-1.33 4.13 4.13 0 0 1-3.552 1.594c-3.607 0-6.394-3.315-6.394-7.478s2.787-7.478 6.394-7.478a4.107 4.107 0 0 1 3.552 1.594 2.143 2.143 0 0 1 1.94-1.33 2.057 2.057 0 0 1 2.068 2.15z"
        transform="translate(-60.663 -123.871)"
        style={{
          fill: "#fff",
        }}
      />
    </g>
  </svg>
);
