import { Section } from "@/components/common/section/Section";
import { ReassuranceCard } from "@/components/layout/footer/reassuranceBlock/reassuranceCard/ReassuranceCard";
import styles from "./ReassuranceBlock.module.scss";
import { ReassuranceCardProps } from "./reassuranceCard/reassuranceCardContent/ReassuranceCardContent";

export const ReassuranceBlock = ({
  reassurances,
}: {
  reassurances: ReassuranceCardProps[];
}) => (
  <Section containerStyle={styles.reassuranceSectionColor}>
    <div className={styles.reassuranceBlock}>
      <div className={styles.reassuranceContainerLayout}>
        <div className={styles.reassuranceCardsLayout}>
          {reassurances.map((cardContent) => (
            <ReassuranceCard key={cardContent.title} {...cardContent} />
          ))}
        </div>
      </div>
    </div>
  </Section>
);
