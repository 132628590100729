import { Breadcrumbs as BaseBreadcrumbs } from "@mantine/core";
import Link from "next/link";
import styles from "./Breadcrumbs.module.scss";

type Breadcrumb = { title: string; url: string };

const Breadcrumb = ({ title, url }: Breadcrumb) => (
  <Link href={url}>
    <a className={styles.clickableBreadcrumb}>{title}</a>
  </Link>
);

const NonClickableBreadcrumb = ({ title }: Breadcrumb) => (
  <p className={styles.breadcrumb}>{title}</p>
);

type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => (
  <div className={styles.breadcrumbsContainer}>
    <BaseBreadcrumbs
      separator=">"
      classNames={{
        root: styles.rootBreadcrumbs,
      }}
    >
      {breadcrumbs.map((breadcrumb, index) =>
        index === breadcrumbs.length - 1 ? (
          <NonClickableBreadcrumb key={breadcrumb.url} {...breadcrumb} />
        ) : (
          <Breadcrumb key={breadcrumb.url} {...breadcrumb} />
        )
      )}
    </BaseBreadcrumbs>
  </div>
);
