import Image from "next/image";

import {
  addBasePathToStrapiMedia,
  formatStrapiEntity,
  StrapiEntity,
  StrapiImage,
} from "@/utils/strapi";

import styles from "./ReassuranceCardContent.module.scss";

export type ReassuranceCardProps = {
  title: string;
  subtitle: string;
  image: StrapiEntity<StrapiImage>;
  url: string | null;
};

export const ReassuranceCardContent = ({
  title,
  subtitle,
  image,
  url,
}: ReassuranceCardProps) => {
  const strapiImage = formatStrapiEntity(image);

  return (
    <div className={`${styles.cardLayout} ${url !== null ? styles.link : ""}`}>
      <Image
        width={64}
        height={64}
        layout="fixed"
        src={addBasePathToStrapiMedia(strapiImage)}
        alt={strapiImage.alternativeText}
      />
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
};
