const defaultSize = 30;
const defaultColor = "white";

type CrossIconProps = {
  size?: number;
  color?: string;
};

export const CrossIcon = ({
  size = defaultSize,
  color = defaultColor,
}: CrossIconProps) => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 30 30"
    enableBackground="new 0 0 30 30"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path fill="none" d="M30,30H0V0h30V30z" />
    <path
      fill={color}
      d="M22.1,24L6,7.8C5.7,7.5,5.7,7.1,6,6.8l0,0c0.3-0.3,0.8-0.3,1.1,0l16.1,16.1c0.3,0.3,0.3,0.8,0,1.1l0,0
	C22.9,24.3,22.4,24.3,22.1,24z"
    />
    <path
      fill={color}
      d="M23.5,8L7.3,24.1c-0.3,0.3-0.8,0.3-1.1,0l0,0c-0.3-0.3-0.3-0.8,0-1.1L22.4,6.9c0.3-0.3,0.8-0.3,1.1,0l0,0
	C23.8,7.2,23.8,7.7,23.5,8z"
    />
  </svg>
);
