import { FacebookIcon } from "@/public/icons/FacebookIcon";
import { LinkedInIcon } from "@/public/icons/LinkedInIcon";
import { RentACarIcon } from "@/public/icons/RentACarIcon";
import { TwitterIcon } from "@/public/icons/TwitterIcon";
import { InstagramIcon } from "@/public/icons/InstagramIcon";
import { links } from "@/routes/links";
import styles from "./SocialMedia.module.scss";

const openLinkInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener noreferrer");
};

const socialMedias = [
  { title: "Instagram", link: links.INSTAGRAM, icon: <InstagramIcon /> },
  { title: "Facebook", link: links.FACEBOOK, icon: <FacebookIcon /> },
  { title: "LinkedIn", link: links.LINKEDIN, icon: <LinkedInIcon /> },
  { title: "Twitter", link: links.TWITTER, icon: <TwitterIcon /> },
];

export const SocialMedia = () => (
  <div className={styles.socialMediaContainer}>
    <div className={styles.socialMediaIcons}>
      {socialMedias.map((socialMedia) => (
        <button
          type="button"
          key={socialMedia.title}
          title={socialMedia.title}
          className={styles.icon}
          onClick={() => openLinkInNewTab(socialMedia.link)}
        >
          {socialMedia.icon}
        </button>
      ))}
    </div>
    <div className={styles.racIcon}>
      <RentACarIcon />
    </div>
  </div>
);
