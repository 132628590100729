import Link from "next/link";
import styles from "./AgenciesListFooter.module.scss";

type AgencyLinks = {
  name: string;
  url: string;
};

export type AgenciesListFooterProps = {
  title: string;
  agencyLinks: AgencyLinks[];
};

export const AgenciesListFooter = ({
  title,
  agencyLinks,
}: AgenciesListFooterProps) => {
  const onClickCta = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className={styles.agenciesListContainer}>
      <p className={styles.agenciesListTitle}>{title}</p>
      {agencyLinks.map((agencyLink) => {
        if (agencyLink.name === "Nous rejoindre") {
          return (
            <div
              key={agencyLink.name}
              className={styles.agencyLink}
              onClick={() => onClickCta(agencyLink.url)}
            >
              {agencyLink.name}
            </div>
          );
        } else {
          return (
            <div key={agencyLink.name} className={styles.agencyLink}>
              <Link href={agencyLink.url}>
                <a>{agencyLink.name}</a>
              </Link>
            </div>
          );
        }
      })}
    </div>
  );
};
