type MenuCloseProps = {
  color?: string;
  colorClass?: string;
  width?: string;
  height?: string;
};

export const ModalClose = ({
  color,
  colorClass,
  width,
  height,
}: MenuCloseProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "50"}
    height={height ?? "50"}
    viewBox="0 0 50 50"
  >
    <g data-name="Menu">
      <path
        data-name="Rectangle 842"
        d="M0 0h50v50H0z"
        transform="rotate(180 25 25)"
        fill="none"
      />
      <g data-name="Groupe 8224">
        <path
          data-name="Rectangle 838"
          transform="translate(10 10)"
          d="M0 0h30v30H0z"
          fill="none"
        />
        <g transform="translate(16.105 15.334)">
          <rect
            width="24.313"
            height="1.499"
            rx=".75"
            transform="rotate(-45 20.753 8.596)"
            stroke={color}
            className={colorClass}
          />
          <g data-name="Groupe 8213" transform="translate(.319 .163)">
            <rect
              width="11.994"
              height="1.499"
              rx=".75"
              transform="rotate(45 .53 1.28)"
              stroke={color}
              className={colorClass}
            />
            <rect
              width="19.49"
              height="1.499"
              rx=".75"
              transform="rotate(45 -1.852 7.03)"
              stroke={color}
              className={colorClass}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
