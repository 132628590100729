import { ReassuranceBlock } from "@/components/layout/footer/reassuranceBlock/ReassuranceBlock";
import {
  AgenciesAndSocialMediaBlock,
  AgenciesBlock,
} from "@/components/layout/footer/agenciesAndSocialMediaBlock/AgenciesAndSocialMediaBlock";
import { ReassuranceCardProps } from "@/components/layout/footer/reassuranceBlock/reassuranceCard/reassuranceCardContent/ReassuranceCardContent";
import {
  LegalLinksBlock,
  LegalLinksBlockProps,
} from "@/components/layout/footer/legalLinksBlock/LegalLinksBlock";

export type FooterProps = {
  reassurances: ReassuranceCardProps[];
  agenciesBlock: AgenciesBlock;
  legalLinksBlock: LegalLinksBlockProps;
};

export const Footer = ({
  reassurances,
  agenciesBlock,
  legalLinksBlock,
}: FooterProps) => (
  <div>
    <ReassuranceBlock reassurances={reassurances} />
    <AgenciesAndSocialMediaBlock agenciesBlock={agenciesBlock} />
    <LegalLinksBlock legalLinks={legalLinksBlock.legalLinks} />
  </div>
);
