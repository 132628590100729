import { Dispatch, SetStateAction, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import serviceClient from "@/public/images/logo_service_client.png";
import { BurgerIcon } from "@/public/icons/BurgerIcon";
import { CrossIcon } from "@/public/icons/CrossIcon";
import { ArrowIcon } from "@/public/icons/ArrowIcon";
import { SectionWithLinks } from "@/components/layout/headerLegacy/menuMobile/sectionWithLinks/SectionWithLinks";
import { SectionWithSubSections } from "@/components/layout/headerLegacy/menuMobile/sectionWithSubSections/SectionWithSubSections";
import { Menu, SectionType } from "../menuDesktop/MenuDesktop";
import styles from "./MenuMobile.module.scss";

type MenuMobileProps = {
  menu: Menu;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const MenuMobile = ({ menu, isOpen, setIsOpen }: MenuMobileProps) => {
  const [sectionOpened, setSectionOpened] = useState("");
  const [subSectionOpened, setSubSectionOpened] = useState("");

  const setStateToReset = () => {
    if (subSectionOpened) {
      setSubSectionOpened("");
    } else {
      setSectionOpened("");
    }
  };

  return (
    <div className={styles.menuBurger}>
      {isOpen ? (
        <div
          className={styles.icon}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <CrossIcon />
        </div>
      ) : (
        <div
          className={styles.icon}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <BurgerIcon />
        </div>
      )}
      <div>
        <div className={isOpen ? styles.menuIn : styles.menuOut}>
          {sectionOpened && (
            <div onClick={setStateToReset} className={styles.backButton}>
              <div className={styles.leftArrow}>
                <ArrowIcon color="#445871" />
              </div>
              <p className={styles.backButtonText}>Retour</p>
            </div>
          )}
          {menu.menuSection.map((section, index) => {
            if (section.type === SectionType.SINGLE && section.single) {
              return (
                <SectionWithLinks
                  title={section.title}
                  links={section.single}
                  sectionOpened={sectionOpened}
                  setSectionOpened={setSectionOpened}
                  setIsOpen={setIsOpen}
                  key={index}
                />
              );
            } else if (section.type === SectionType.COLUMN && section.column) {
              return (
                <SectionWithSubSections
                  title={section.title}
                  subSectionsWithLinks={section.column}
                  sectionOpened={sectionOpened}
                  setSectionOpened={setSectionOpened}
                  subSectionOpened={subSectionOpened}
                  setSubSectionOpened={setSubSectionOpened}
                  setIsOpen={setIsOpen}
                  key={index}
                />
              );
            } else if (section.type === SectionType.ROW && section.row?.rows) {
              return (
                <SectionWithSubSections
                  title={section.title}
                  subSectionsWithLinks={section.row.rows}
                  sectionOpened={sectionOpened}
                  setSectionOpened={setSectionOpened}
                  subSectionOpened={subSectionOpened}
                  setSubSectionOpened={setSubSectionOpened}
                  setIsOpen={setIsOpen}
                  key={index}
                />
              );
            }
          })}
          <div className={styles.customerServiceIcon}>
            <Link
              href={
                "https://rac-production-strapi-uploads.s3.eu-west-3.amazonaws.com/ESCDA_2025_fb66fd3455.pdf"
              }
            >
              <a
                target={"_blank"}
                rel="noreferrer"
                className={styles.customerServiceLink}
              >
                <Image src={serviceClient} alt="Service Client" layout="fill" />
              </a>
            </Link>
          </div>
        </div>
        {isOpen && (
          <div
            className={styles.shadow}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
