import { Dispatch, SetStateAction } from "react";
import Link from "next/link";

import { ArrowIcon } from "@/public/icons/ArrowIcon";

import styles from "./SectionWithLinks.module.scss";

type LinkProps = {
  title: string;
  url: string;
  obfuscate?: boolean;
};

export type SectionWithLinksProps = {
  title: string;
  links: LinkProps[];
  sectionOpened: string;
  setSectionOpened: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const SectionWithLinks = ({
  title,
  links,
  sectionOpened,
  setSectionOpened,
  setIsOpen,
}: SectionWithLinksProps) => {
  const redirectToExternalUrl = (url: string) => (window.location.href = url);

  return (
    <div>
      {!sectionOpened && (
        <div
          className={styles.sectionWithLinksTitle}
          onClick={() => {
            setSectionOpened(title);
          }}
        >
          <p>{title}</p>
          <div className={styles.arrowContainer}>
            <ArrowIcon color="#445871" />
          </div>
        </div>
      )}
      {sectionOpened === title && (
        <div>
          <div className={styles.sectionWithLinksTitleOpened}>
            <p>{title}</p>
            <div className={styles.downArrowContainer}>
              <ArrowIcon color="#062e5e" />
            </div>
          </div>
          <div className={styles.linkList}>
            {links.map(({ title: linkTitle, url, obfuscate }) => (
              <div className={styles.link} key={linkTitle}>
                {obfuscate ? (
                  <button
                    onClick={() => {
                      redirectToExternalUrl(url);
                      setIsOpen(false);
                    }}
                  >
                    {linkTitle}
                  </button>
                ) : (
                  <Link href={url}>
                    <a
                      href={url}
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      {linkTitle}
                    </a>
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
